import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "flex flex-row text-12px xxl:text-14px items-center w-full" }
const _hoisted_3 = { class: "font-semibold text-20px mr-8 whitespace-nowrap text-black-lp-200 capitalize" }
const _hoisted_4 = { class: "flex flex-col w-full gap-y-2 mb-2 md:flex-row md:gap-y-2 md:mb-2 lg:w-auto lg:gap-y-0 lg:mb-0" }
const _hoisted_5 = { class: "space-y-2 px-2 pb-4" }
const _hoisted_6 = { class: "overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Select = _resolveComponent("Select")!
  const _component_advanced_filter = _resolveComponent("advanced-filter")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_UploadForm = _resolveComponent("UploadForm")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, null, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createVNode("div", _hoisted_4, [
              _createVNode(_component_Search, {
                class: "w-full",
                onInput: _ctx.onSearch,
                onClear: _ctx.clearSearch,
                value: _ctx.searchValue,
                placeholder: "Cari nama atau nomor telepon"
              }, null, 8, ["onInput", "onClear", "value"]),
              _createVNode(_component_advanced_filter, {
                "custom-max-height": "42vh",
                addSumFilter: _ctx.sumFilter,
                onReset: _ctx.onResetAdvancedFilter
              }, {
                default: _withCtx(() => [
                  _createVNode("div", _hoisted_5, [
                    _createVNode(_component_DataWrapper, { label: 'Kecamatan, kota' }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectSearch, {
                          class: "mt-2",
                          onFilter: _ctx.fetchdestinationCity,
                          isLoading: _ctx.isLoadingdestinationCity,
                          options: _ctx.destinationCityList,
                          keyName: "name",
                          keyValue: "id",
                          placeholder: 'Pilih kecamatan, kota',
                          modelValue: _ctx.destinationCity,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.destinationCity = $event)),
                          onFocus: _ctx.onFocusdestinationCity,
                          onChange: _ctx.onChangeDestinationCity
                        }, null, 8, ["onFilter", "isLoading", "options", "modelValue", "onFocus", "onChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DataWrapper, { label: "Kode pos" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectSearch, {
                          class: "mt-2",
                          onFilter: this.fetchZipCode,
                          onChange: _ctx.onChangeZipCode,
                          isLoading: _ctx.isLoadingZipCode,
                          options: _ctx.zipCodeList,
                          keyName: "name",
                          keyValue: "code",
                          placeholder: 'Ketik & pilih kode pos',
                          modelValue: _ctx.zipCode,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.zipCode = $event)),
                          onFocus: _ctx.onFocusZipCode
                        }, null, 8, ["onFilter", "onChange", "isLoading", "options", "modelValue", "onFocus"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DataWrapper, { label: "Urutkan" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Select, {
                          dataSelect: _ctx.orderByData,
                          onOpenSelect: _ctx.onOpenOrderBy,
                          onCloseSelect: _ctx.onCloseOrderBy,
                          onChooseSelect: _ctx.onSelectOrderBy,
                          value: _ctx.orderByTitle,
                          isOpenMenu: _ctx.orderBySelect,
                          isDefaultBlack: true,
                          class: "w-full z-10"
                        }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["addSumFilter", "onReset"])
            ])
          ]),
          _createVNode(_component_lp_button, {
            class: "whitespace-nowrap mr-3",
            onClick: _ctx.onOpenUpload,
            title: "Bulk pelanggan",
            textColor: "red-lp-300",
            outline: "",
            iconV2: "",
            iconLeft: "bulk-upload"
          }, null, 8, ["onClick"]),
          _createVNode(_component_lp_button, {
            class: "whitespace-nowrap",
            onClick: _ctx.goAdd,
            title: "Pelanggan",
            textColor: "white",
            iconV2: "",
            iconLeft: "plus-icon"
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_TableV2, {
          loading: _ctx.apiListData.loading,
          borderTop: false,
          borderBottom: false,
          columns: _ctx.columns,
          data: _ctx.listData.data,
          isEmpty: _ctx.isEmpty,
          iconEmpty: _ctx.emptyIcon,
          emptyHeaderMessage: _ctx.emptyTitle,
          emptyMessage: _ctx.emptyMessage,
          class: "my-2",
          errorCause: _ctx.apiListData.errorType,
          isDetailAble: "",
          pagination: _ctx.listData.pagination,
          "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => (_ctx.listData.pagination = $event)),
          onRequest: _ctx.getCustomerManagementList,
          onTryAgain: _ctx.getCustomerManagementList,
          paginationStyle: "v3",
          totalColumnFreeze: 1,
          groupAction: true,
          isRightFreeze: true,
          isPinnedScrollBottom: "",
          groupOptions: _ctx.actionGroupOptions
        }, null, 8, ["loading", "columns", "data", "isEmpty", "iconEmpty", "emptyHeaderMessage", "emptyMessage", "errorCause", "pagination", "onRequest", "onTryAgain", "groupOptions"])
      ])
    ]),
    (_ctx.isOpenUpload)
      ? (_openBlock(), _createBlock(_component_UploadForm, {
          key: 0,
          page: _ctx.page,
          onOnCloseForm: _ctx.onChangeAdvancedFilter
        }, null, 8, ["page", "onOnCloseForm"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.handlerDelete,
      onClose: _ctx.onCloseModalDelete,
      title: "Konfirmasi hapus",
      message: "Anda yakin ingin menghapus data pelanggan ini ?",
      image: "badge-confirmation-general",
      textSuccess: "Hapus data",
      textCancel: "Kembali",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose"]), [
      [_vShow, _ctx.openModalDelete]
    ])
  ], 64))
}