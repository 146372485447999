
import { Vue, Options } from "vue-class-component";
import { BulkController } from "@/app/ui/controllers/BulkController";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import RiwayatUploadFile from "./riwayat-upload-file.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";

@Options({
  name: "UploadForm",
  components: {
    UploadFile,
    RiwayatUploadFile,
  },
  emits:["onCloseForm"],
})
export default class extends Vue {
  mounted() {
    this.getBulkList();
  }
  props: any = this.$props;
  // State Value
  archiveType = "customer-management";
  activityName = "";
  versionName = "";

  // Handle File
  archiveFile: any = "";
  isClearFile = false;

  statusReset = false;

  maxActivityNameLength = 255;

  get isOpenUpload() {
    return BulkController.isOpenUpload;
  }

  get isLoading() {
    return BulkController.isLoading;
  }

  get message() {
    return BulkController.message;
  }

  get type() {
    return BulkController.type;
  }

  public get bulkListData() {
    return BulkController.bulkData.bulkData;
  }

  //  Is disabled
  get isDisabled() {
    return !this.activityName || !this.archiveFile;
  }

  onResetForm() {
    this.activityName = "";
    this.archiveFile = "";
    this.isClearFile = true;
  }

  getBulkList() {
    BulkController.getBulkList({
      activityName: "",
      archiveType: "customer-management",
      status: "",
      page: 1,
      limit: 10,
    });
  }

  async onSubmit() {
    MainAppController.showLoading();
    const resp = await BulkController._onBulkCustomerUploadCsv({
      activityName: this.activityName,
      archiveFile: this.archiveFile,
      isBulkUploadForm: false,
    });
    if (resp) {
      this.onResetForm();
      BulkController.searchActivity("");
      BulkController.selectStatus("");
      BulkController.selectType("");
      BulkController.setFirstPage();
      this.getBulkList();
    } else {
      this.showErrorMessage();
    }
    MainAppController.closeLoading();
  }

  directTemplate() {
    this.$router.push("/bulk-action/bulk-download/template");
  }

  showErrorMessage(){
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "new-badge-failed",
        title: `Aktivitas Upload Gagal`,
        message:`Aktivitas “${this.archiveFile?.name}” gagal terupload`,
        textSuccess: "OK",
        onSubmit: () => MainAppController.closeMessageModal()
      })
    );
  }

  onCloseWithReset() {
    this.onResetForm();
    BulkController.setIsOpenUpload(false);
    this.$emit("onCloseForm");
  }

  get configUploadFile() {
    return {
      type: "csv",
      maxRows: 25000,
    };
  }
}
