
import { Vue, prop } from "vue-class-component";
import { formatDate } from "@/app/infrastructures/misc/Utils";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";

class Props {
  data = prop<any>({
    required: true,
    type: Object,
  });
}

export default class RiwayatUploadFile extends Vue.with(Props) {
  formatDate = formatDate;

  get statusString() {
    switch (this.data.status.toLowerCase()) {
      case "success":
        return "Berhasil";
      case "partially":
      case "waiting":
        return "Behasil sebagian";
      case "failed":
        return "Gagal";
      case "uploading":
        return "Uploading";
      default:
        return "";
    }
  }

  fileName(data: any) {
    let result: any = "";
    switch (data.status.toLowerCase()) {
      case "partially":
        result = "File error";
        break;
      case "failed":
        result = "File yang gagal";
        break;
      case "success":
        result = "File asli";
        break;  
      default:
        result = this.getFilenameWithoutExtension(data.archive_file);
        break;
    }
    return result;
  }

  getFilenameWithoutExtension(url: string) {
    const parts = url.split("/");
    const filenameWithExtension = parts.pop();

    // If filenameWithExtension is undefined, return undefined
    if (!filenameWithExtension) {
      return undefined;
    }

    // Remove the extension by slicing from the start to the last '.'
    const filenameWithoutExtension = filenameWithExtension
      .split(".")
      .slice(0, -1)
      .join(".");

    return filenameWithoutExtension;
  }

  downloadFile() {
    downloadFile(
      this.data.status.toLowerCase() === "failed" ||
        this.data.status.toLowerCase() === "partially"
        ? this.data.failed_file
        : this.data.archive_file,
      this.data.activityName,
      `.csv`
    );
  }
}
